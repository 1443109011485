import http from 'http';
import https from 'https';
import config from 'config';
var _config$get = config.get('server.dataSource'),
  _config$get$host = _config$get.host,
  host = _config$get$host === void 0 ? '' : _config$get$host,
  maxSockets = _config$get.maxSockets,
  _config$get$keepAlive = _config$get.keepAlive,
  keepAlive = _config$get$keepAlive === void 0 ? true : _config$get$keepAlive;
var protocol = host.startsWith('https') ? https : http;
var options = {
  keepAlive: JSON.parse(keepAlive)
};
if (maxSockets) options.maxSockets = maxSockets;
var agent = new protocol.Agent(options);
var getServerFetchOptions = function getServerFetchOptions() {
  return {
    agent: agent
  };
};
export default getServerFetchOptions;