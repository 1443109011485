
import { ApolloClient, InMemoryCache, ApolloLink } from '@apollo/client';
import { whereAmI } from '@magalu/mixer-utils';
import { createAuthLink, createLogLink, createHttpLink, createOnErrorLink, concatTimeoutLink } from './middleware';
import policies from './cachePolicies';
import typeDefs from './localTypeDefs';
var serverFetchPolicy = {
  errorPolicy: 'all',
  fetchPolicy: 'no-cache'
};
var serverDefaultOptions = {
  mutate: serverFetchPolicy,
  query: serverFetchPolicy,
  watchQuery: serverFetchPolicy
};
var cachedApollo;
export default function apolloClient() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    _ref$initialState = _ref.initialState,
    initialState = _ref$initialState === void 0 ? {} : _ref$initialState,
    _ref$config = _ref.config,
    config = _ref$config === void 0 ? {} : _ref$config,
    token = _ref.token;
  var newApollo = function newApollo() {
    var _publicRuntimeConfig$, _serverRuntimeConfig$;
    var publicRuntimeConfig = config.publicRuntimeConfig,
      serverRuntimeConfig = config.serverRuntimeConfig;
    var dataSourceTimeout = whereAmI.onClient ? publicRuntimeConfig == null ? void 0 : (_publicRuntimeConfig$ = publicRuntimeConfig.dataSource) == null ? void 0 : _publicRuntimeConfig$.timeout : serverRuntimeConfig == null ? void 0 : (_serverRuntimeConfig$ = serverRuntimeConfig.dataSource) == null ? void 0 : _serverRuntimeConfig$.timeout;
    var timeout = "".concat(dataSourceTimeout);
    var httpLink = createHttpLink(config);
    var timeoutLink = concatTimeoutLink(httpLink, timeout);
    var link = ApolloLink.from([createAuthLink(token), createOnErrorLink(config), createLogLink(), timeoutLink]);
    var cache = new InMemoryCache({
      typePolicies: policies
    }).restore(initialState);
    return new ApolloClient({
      cache: cache,
      connectToDevTools: whereAmI.onClient && (publicRuntimeConfig == null ? void 0 : publicRuntimeConfig.environment) !== 'production',
      defaultOptions: whereAmI.onServer ? serverDefaultOptions : {},
      link: link,
      ssrMode: whereAmI.onServer,
      typeDefs: typeDefs
    });
  };
  var apollo = cachedApollo || newApollo();

  if (whereAmI.onClient && !cachedApollo) cachedApollo = apollo;
  return apollo;
}