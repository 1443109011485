
import { ApolloLink } from '@apollo/client';
import { whereAmI } from '@magalu/mixer-utils';

export default (function () {
  return new ApolloLink(function (operation, forward) {
    var startTime = new Date().getTime();
    return forward(operation).map(function (result) {
      if (process.env.DEBUG) {
        var operationType = operation.query.definitions[0].operation;
        var ellapsed = new Date().getTime() - startTime;
        var title = "%c[apollo log] ".concat(operationType, " ").concat(operation.operationName, " - (in ").concat(ellapsed, " ms)\n");
        if (whereAmI.onServer) {
          console.log('\n\n ------------------------------------------------------------');
        }
        console.groupCollapsed(title, 'color: #bada55');
        console.log("\uD83C\uDF55 SEND", operation);
        console.log("\uD83C\uDF55 RESP", result);
        console.groupEnd(title);
      }
      return result;
    });
  });
});