
import React, { createContext } from 'react';
import { client as apolloClient } from '../client';
export var ClientContext = createContext();
var Provider = ClientContext.Provider;
export { Provider };
export var ClientProvider = function ClientProvider(_ref) {
  var children = _ref.children,
    config = _ref.config,
    cookies = _ref.cookies,
    token = _ref.token;
  var memoizedClient = React.useMemo(function () {
    return apolloClient({
      config: config,
      cookies: cookies,
      token: token
    });
  }, [JSON.stringify(config), token]);
  return React.createElement(Provider, {
    value: memoizedClient
  }, children);
};