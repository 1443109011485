
import { trace } from '@opentelemetry/api';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import isNaN from 'lodash/isNaN';
import isEmpty from 'lodash/isEmpty';
import { onError } from '@apollo/client/link/error';
import { Logger, whereAmI } from '@magalu/mixer-utils';

var handleTimeoutError = function handleTimeoutError(errorName, operation, response) {
  var span = trace.getActiveSpan();
  var variables = omitBy(operation == null ? void 0 : operation.variables, function (v) {
    return isNil(v) || isNaN(v) || isEmpty(v);
  });
  span == null ? void 0 : span.addEvent(errorName, {
    'log.message': JSON.stringify(variables) || JSON.stringify(operation == null ? void 0 : operation.operationName),
    'log.severity': 'error'
  });

  if ((operation == null ? void 0 : operation.operationName) === 'suggestQuery' && (response == null ? void 0 : response.errors)) {
    response.errors = undefined;
  }
};

export default (function () {
  var config = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return onError(function (_ref) {
    var graphQLErrors = _ref.graphQLErrors,
      networkError = _ref.networkError,
      operation = _ref.operation,
      response = _ref.response;
    var logger = Logger(config);
    (graphQLErrors || []).forEach(function (_ref2) {
      var message = _ref2.message,
        locations = _ref2.locations,
        path = _ref2.path,
        extensions = _ref2.extensions;
      if ((response == null ? void 0 : response.errors) && (operation == null ? void 0 : operation.operationName) !== 'root') {
        if (whereAmI.onClient) {
          response.errors = [{
            extensions: extensions,
            message: message
          }];
        } else {
          response.errors = undefined;
        }
      }
      if ((extensions == null ? void 0 : extensions.reason) === 'request timed out' || (extensions == null ? void 0 : extensions.code) === 'REQUEST_TIMEOUT') {
        handleTimeoutError('mixer.graphql.federation.timeout', operation, response);
      } else {
        logger.error("[apolloClient] - GraphQl Error - message: ".concat(message, " - location: ").concat(JSON.stringify(locations), " - path: ").concat(path, " - variables: ").concat(JSON.stringify(operation == null ? void 0 : operation.variables), " - operation: ").concat(operation == null ? void 0 : operation.operationName));
      }
    });
    if (networkError == null ? void 0 : networkError.timeout) {
      handleTimeoutError('mixer.graphql.query.timeout', operation, response);
      logger.error("[ApolloClient] - Timeout error - variables: ".concat(JSON.stringify(operation == null ? void 0 : operation.variables), " - operation: ").concat(operation == null ? void 0 : operation.operationName));
    }
  });
});