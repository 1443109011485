
import { whereAmI } from '@magalu/mixer-utils';
var getFetchOptions = function getFetchOptions() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
    _ref$browser = _ref.browser,
    browser = _ref$browser === void 0 ? whereAmI.onClient : _ref$browser;
  if (browser) return {};
  var getServerFetchOptions = require('./getServerFetchOptions')["default"];
  return getServerFetchOptions();
};
export default getFetchOptions;