import { sha256 } from 'crypto-hash';
import { HttpLink } from '@apollo/client';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { Logger, whereAmI } from '@magalu/mixer-utils';
import getFetchOptions from '../getFetchOptions';
export var disableNextPersistedQueries = function disableNextPersistedQueries(logger) {
  return function (errorResponse) {
    var _errorResponse$graphQ;
    var isPersistedQueryNotSupported = !!(errorResponse == null ? void 0 : (_errorResponse$graphQ = errorResponse.graphQLErrors) == null ? void 0 : _errorResponse$graphQ.find(function (_ref) {
      var extensions = _ref.extensions;
      return (extensions == null ? void 0 : extensions.code) === 'PERSISTED_QUERY_NOT_SUPPORTED';
    }));
    logger.error('[ApolloClient] - Persisted Link Error:', errorResponse);
    return isPersistedQueryNotSupported;
  };
};
export default (function (config) {
  var publicRuntimeConfig = config.publicRuntimeConfig,
    serverRuntimeConfig = config.serverRuntimeConfig;
  var runtimeConfig = whereAmI.onClient ? publicRuntimeConfig : serverRuntimeConfig;
  var logger = Logger(config);
  var _ref2 = (runtimeConfig == null ? void 0 : runtimeConfig.dataSource) || {},
    host = _ref2.host,
    _ref2$useAPQ = _ref2.useAPQ,
    useAPQ = _ref2$useAPQ === void 0 ? false : _ref2$useAPQ,
    _ref2$useGETForHashed = _ref2.useGETForHashedQueries,
    useGETForHashedQueries = _ref2$useGETForHashed === void 0 ? false : _ref2$useGETForHashed;
  var uri = "".concat(host, "/graphql");
  var httpLink = new HttpLink({
    fetch: fetch,
    fetchOptions: getFetchOptions({
      config: runtimeConfig
    }),
    uri: uri
  });
  if (useAPQ) {
    var persistedLink = createPersistedQueryLink({
      disable: disableNextPersistedQueries(logger),
      sha256: sha256,
      useGETForHashedQueries: useGETForHashedQueries
    });
    return persistedLink.concat(httpLink);
  }
  return httpLink;
});